import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

function setSinglePost(data) {
  return data.allMarkdownRemark.pageInfo.totalCount === 1 ? "news-item news-item--singlePost" : "news-item"
}

export default props => (
  <StaticQuery
    query = {graphql`
      query BlockIndexQuery {
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              excerpt(pruneLength: 210)
              id
              frontmatter {
                title
                date(formatString: "DD MMMM YYYY")
                path
                featuredImage {
                  childImageSharp {
                    sizes(maxWidth: 630) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
            }
          }
          pageInfo {
            totalCount
          }
        }
      }
    `}
    render={ data => (
      <React.Fragment>
        {data.allMarkdownRemark.edges.slice(0, `${props.limit}`).map(({ node }) => (
          <Link className={setSinglePost(data)} key={node.id} to={ node.frontmatter.path }>
            <div className="news-item__image"><Image sizes={node.frontmatter.featuredImage.childImageSharp.sizes} /></div>
            <div className="news-item__text">
              <h3 className="news-item__title">{ node.frontmatter.title }</h3>
              <div className="news-item__published">{ node.frontmatter.date }</div>
              <div className="news-item__excerpt">{ node.excerpt }</div>
            </div>
          </Link>
        ))}
    </React.Fragment>
    ) }
  />
)
