import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import NewsList from "../components/news-list"

export default (props) => {

  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'news'}}>
        <title>Latest News</title>
      </Helmet>
      <PageHeader headerText="Latest News" />
      <div className="news__list news__list--page news make__grid">
        <NewsList limit="100" />
      </div>
    </Layout>
  )
}
